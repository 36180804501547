<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto">
        <v-btn small class="ml-5 mt-5" color="error" dark @click="fetchProbableCauseData()">
          <v-icon v-if="!isLoading" left>mdi-creation</v-icon>
          <v-progress-circular v-else indeterminate size="24"></v-progress-circular>
          ASO Insights
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showProbableCause" max-width="500px">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-creation</v-icon>
          ASO Insights
        </v-card-title>
        <v-card-text>
          <span v-html="probableCauseSummary"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="onLike">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn icon @click="onDislike">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showProbableCause = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="filteredSignalInstances"
      :items-per-page="25"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
      }"
      @click:row="handleRowClick"
      class="signal-instance-table"
    >
      <template v-slot:item.signal="{ item }">
        <signal-popover
          :signal="item.signal"
          :caseData="item.case"
        />
      </template>
      <template v-slot:item.entities="{ item }">
        <v-row>
          <v-col class="d-flex flex-wrap">
            <v-chip
              v-for="(entity, index) in item.entities"
              :key="index"
              class="mr-2 mb-2"
              small
            >
              <entity-popover :entity="entity" />
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.created_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-actions="{ item }">
        <v-btn icon @click.stop="showRun({ type: 'signal', data: item })">
          <v-icon color="success">mdi-play-circle-outline</v-icon>
        </v-btn>
        <raw-signal-viewer v-model="item.raw" />
        <v-icon small class="chat-hint ml-2">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
    <workflow-run-modal />
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SignalPopover from "@/signal/SignalPopover.vue"
import RawSignalViewer from "@/signal/RawSignalViewer.vue"
import WorkflowRunModal from "@/workflow/RunModal.vue"
import EntityPopover from "@/entity/EntityPopover.vue"

export default {
  name: "SignalInstanceTab",
  components: {
    SignalPopover,
    RawSignalViewer,
    WorkflowRunModal,
    EntityPopover,
  },
  props: {
    inputSignalInstances: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menu: false,
      workflowRunDialog: false,
      search: "",
      headers: [
        { text: "Signal", value: "signal", sortable: false },
        { text: "Entities", value: "entities", sortable: false },
        { text: "Created At", value: "created_at" },
        { text: "", value: "data-table-actions", sortable: false, align: "end", width: "120px" },
      ],
      showProbableCause: false,
      probableCauseSummary: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapFields("case_management", ["selected.signal_instances", "selected.id"]),
    signalInstances() {
      if (this.inputSignalInstances.length) {
        return this.inputSignalInstances
      }
      return this.signal_instances
    },
    filteredSignalInstances() {
      const searchLower = this.search.toLowerCase();
      return this.signalInstances.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
    case_id() {
      if (this.inputSignalInstances.length) {
        return this.inputSignalInstances[0].case_id
      }
      return this.id || null
    },
  },
  methods: {
    ...mapActions("workflow", ["showRun"]),
    ...mapActions("case_management", ["getProbableCause"]),
    ...mapActions('websocket', ['setActiveObject']),

    async fetchProbableCauseData() {
      try {
        const caseId = this.case_id || this.id;
        this.isLoading = true;
        const payload = { caseId: caseId };
        const response = await this.getProbableCause(payload);
        this.probableCauseSummary = response
        this.showProbableCause = true;
      } catch (error) {
        console.error("Error fetching probable cause data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    onLike() {
      this.showProbableCause = false;
    },
    onDislike() {
      this.showProbableCause = false;
    },
    handleRowClick(item) {
      this.setActiveObject({
        type: "SignalInstance",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
}
</script>

<style scoped>
.signal-instance-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.signal-instance-table ::v-deep tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.1) !important;
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.signal-instance-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.signal-instance-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
