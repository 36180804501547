<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <!-- Search Input -->
      <v-text-field
        v-model="search"
        label="Search"
        class="mb-4"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="filteredSubmissions"
        :items-per-page="10"
        @click:row="handleRowClick"
        :footer-props="{
          'items-per-page-options': [25, 50, 100],
        }"
        class="file-submission-table"
      >
        <template v-slot:item.created_at="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
            </template>
            <span>{{ item.created_at | formatDate }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.updated_at | formatRelativeDate }}</span>
            </template>
            <span>{{ item.updated_at | formatDate }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action>
          <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
        </template>
      </v-data-table>
    </div>
  </template>

  <script>
  import { mapActions, mapState } from "vuex";

  export default {
    name: 'FileSubmissionTab',
    props: {
      fileSubmissions: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        search: "",
        headers: [
          { text: "ID", value: "id", sortable: true },
          { text: "File Name", value: "file_name", sortable: true },
          { text: "File Type", value: "file_type", sortable: true },
          { text: "Status", value: "status", sortable: true },
          { text: "Created At", value: "created_at", sortable: true },
          { text: "Updated At", value: "updated_at", sortable: true },
          { text: "ECCS Project ID", value: "eccs_project_id", sortable: true },
          { text: "", value: "action", sortable: false, width: "48px" },
        ],
      };
    },
    computed: {
      ...mapState('websocket', ['tempMessage', 'showTempMessage']),
      filteredSubmissions() {
        const searchLower = this.search.toLowerCase();
        return this.fileSubmissions.filter((submission) => {
          return submission.file_name.toLowerCase().includes(searchLower) ||
            submission.file_type.toLowerCase().includes(searchLower) ||
            submission.status.toLowerCase().includes(searchLower);
        });
      },
    },
    methods: {
      ...mapActions('websocket', ['setActiveObject', 'showTempMessageFn']),
      handleRowClick(item) {
        this.setActiveObject({
          type: "FileSubmission",
          id: item.id,
        });
        this.$emit('close-dialog');
      },
    },
  };
  </script>

  <style scoped>
  .file-submission-table ::v-deep tbody tr {
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .file-submission-table ::v-deep tbody tr:hover {
    box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
  }

  .chat-hint {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .file-submission-table ::v-deep tbody tr:hover .chat-hint {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .file-submission-table ::v-deep tbody tr:hover .chat-hint {
    animation: pulse 1.5s infinite;
  }
  </style>
