<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="filteredCases"
      :items-per-page="-1"
      disabled-pagination
      hide-default-footer
      @click:row="handleRowClick"
      class="case-table"
    >
      <template v-slot:item.case="{ item }">
        <case-popover :value="item" />
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.created_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-actions>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import CasePopover from "@/case/CasePopover.vue"

export default {
  name: "CaseTab",
  components: {
    CasePopover,
  },
  props: {
    inputCases: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menu: false,
      search: "",
      headers: [
        { text: "Case", value: "case", sortable: false },
        { text: "Priority", value: "case_priority.name", sortable: false },
        { text: "Status", value: "status", sortable: true },
        { text: "Created At", value: "created_at" },
        { text: "", value: "data-table-actions", sortable: false, align: "end", width: "48px" },
      ],
    }
  },
  computed: {
    ...mapFields("case_management", ["selected.cases"]),
    cases() {
      if (this.inputCases.length) {
        return this.inputCases
      }
      return this.cases
    },
    filteredCases() {
      const searchLower = this.search.toLowerCase();
      return this.cases.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: "Case",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
}
</script>

<style scoped>
.case-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.case-table ::v-deep tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.1) !important;
  box-shadow: 0 0 15px rgba(33, 150, 243, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.case-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.case-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
