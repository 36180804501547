<template>
  <v-list>
    <v-list-item v-if="ticket" :href="ticket.weblink" target="_blank">
      <v-list-item-icon>
        <v-icon color="primary">mdi-ticket</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="case-resources-title">Ticket</v-list-item-title>
        <v-list-item-subtitle class="case-resources-subtitle">{{ ticket.description }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-icon>
          <v-icon color="primary">open_in_new</v-icon>
        </v-list-item-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <v-list-item v-if="conversation" :href="conversation.weblink" target="_blank">
      <v-list-item-icon>
        <v-icon color="primary">mdi-message-text-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="case-resources-title">Conversation</v-list-item-title>
        <v-list-item-subtitle class="case-resources-subtitle">{{ conversation.description }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="primary">mdi-open-in-new</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <v-list-item v-if="storycurve_sketch_id" :href="storycurve_url + '/sketch/' + storycurve_sketch_id + '/explore?timeline=' + storycurve_sketch_id" target="_blank">
      <v-list-item-icon>
        <v-icon color="primary">mdi-strategy</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="case-resources-title">Case Analysis & Review</v-list-item-title>
        <v-list-item-subtitle class="case-resources-subtitle">Collaborative forensic & unstructured data analysis and intelligence platform</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-icon>
          <v-icon color="primary">open_in_new</v-icon>
        </v-list-item-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <span v-for="group in groups" :key="group.resource_id">
      <v-list-item :href="group.weblink" target="_blank">
        <v-list-item-icon>
          <v-icon color="primary">mdi-folder-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="case-resources-title">{{ group.resource_type | deslug }}</v-list-item-title>
          <v-list-item-subtitle class="case-resources-subtitle">{{ group.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-icon>
            <v-icon color="primary">open_in_new</v-icon>
          </v-list-item-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
    </span>
    <v-divider />
    <v-list-item v-if="storage" :href="storage.weblink" target="_blank">
      <v-list-item-icon>
        <v-icon color="primary">mdi-database-import-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="case-resources-title">Storage</v-list-item-title>
        <v-list-item-subtitle class="case-resources-subtitle">{{ storage.description }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-icon>
          <v-icon color="primary">open_in_new</v-icon>
        </v-list-item-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <span v-for="document in documents" :key="document.resource_id">
      <v-list-item :href="document.weblink" target="_blank">
        <v-list-item-icon>
          <v-icon color="primary">mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="case-resources-title">{{ document.resource_type | deslug }}</v-list-item-title>
          <v-list-item-subtitle class="case-resources-subtitle">{{ document.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-icon>
            <v-icon color="primary">open_in_new</v-icon>
          </v-list-item-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
    </span>
  </v-list>
</template>

<script>
import { mapFields } from "vuex-map-fields"

export default {
  name: "CaseResourcesTab",

  computed: {
    ...mapFields("case_management", [
      "selected.documents",
      "selected.groups",
      "selected.storage",
      "selected.ticket",
      "selected.storycurve_sketch_id",
      "selected.storycurve_url",
      "selected.conversation",
    ]),
  },
}
</script>

<style scoped>
.case-resources-title {
  font-weight: bold;
}

.case-resources-subtitle {
  color: #616161;
}
</style>
