<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <!-- Search Input -->
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="filteredWeblinks"
      :items-per-page="10"
      @click:row="handleRowClick"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
      }"
      class="weblink-table"
    >
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.created_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.url="{ item }">
        <a :href="item.url" target="_blank">{{ item.url }}</a>
      </template>
      <template v-slot:item.action>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'WeblinkTab',
  props: {
    weblinks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "URL", value: "url", sortable: true },
        { text: "", value: "action", sortable: false, width: "48px" },
      ],
    };
  },
  computed: {
    filteredWeblinks() {
      return this.weblinks.filter((weblink) => {
        return weblink.url.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: "Website",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.weblink-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.weblink-table ::v-deep tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.1) !important;
  box-shadow: 0 0 15px rgba(33, 150, 243, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.weblink-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.weblink-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
