<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <loading v-if="loading"/>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-progress-linear v-if="loading" indeterminate color="info"></v-progress-linear>
    <v-navigation-drawer
      app
      right
      :width="computedWidth"
      clipped
      ref="drawer"
      :permanent="$vuetify.breakpoint.mdAndDown"
    >
      <v-progress-linear v-if="loading" indeterminate color="info"></v-progress-linear>
      <v-expansion-panels popout>
        <v-expansion-panel>
          <v-expansion-panel-header style="background-color: #FFC107;">
            {{ name }}
            <v-spacer />
          </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row>
                        <v-col cols="auto" class="d-flex align-center">
                          <v-icon left>mdi-network-strength-1-alert</v-icon>
                          {{ name }}
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                          <v-chip
                            class="ml-2 mr-2"
                            v-if="status"
                            :color="status == 'New' ? 'error' : status == 'Triage' ? 'warning' : status == 'Escalated' ? 'primary' : 'success'"
                            >
                            <v-icon class="mr-1" v-if="status == 'New'">mdi-network-strength-1-alert</v-icon>
                            <v-icon class="mr-1" v-if="status == 'Triage'">mdi-account-search</v-icon>
                            <v-icon class="mr-1" v-if="status == 'Escalated'">mdi-arrow-up-bold</v-icon>
                            <v-icon class="mr-1" v-if="status == 'Closed'">mdi-lock</v-icon>
                            {{ status }}
                          </v-chip>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                          <v-chip class="mr-2 ml-2">
                            <v-icon
                            v-if="case_priority"
                            :color="case_priority.color"
                            class="mr-1"
                            >mdi-flag-outline</v-icon
                            >
                            {{ case_priority.name }}
                          </v-chip>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                          <v-chip small class="ml-2 mr-2">
                            <v-icon
                              v-if="visibility == 'Open'"
                              small
                              color="success"
                              class="mr-1"
                              >mdi-eye-check</v-icon
                            >
                            <v-icon
                              v-if="visibility == 'Restricted'"
                              small
                              color="warning"
                              class="mr-1"
                              >mdi-eye-off</v-icon
                            >
                            <span v-if="visibility == 'Open'">Public</span>
                            <span v-if="visibility == 'Restricted'">Private</span>
                          </v-chip>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                          <case-participant :participant="assignee" />
                        </v-col>
                      </v-row>
                    </v-list-item-title>

                    <v-list-item-subtitle class="mt-2">
                    </v-list-item-subtitle>

                    <template>
                    <div class="timeline-container">
                      <v-row class="timeline">
                        <!-- Reported State -->
                        <v-col cols="3" class="timeline-item">
                          <div class="timeline-content" :class="{'active': status === 'New'}">
                            <v-icon
                            large
                            :color="reported_at ? 'primary' : 'grey'"
                            >
                            mdi-state-machine
                          </v-icon>
                            <span>Reported</span>
                            <div>{{ reported_at | formatRelativeDate }}</div>
                          </div>
                        </v-col>

                        <!-- Triage State -->
                        <v-col cols="3" class="timeline-item">
                          <div class="timeline-content" :class="{'active': status === 'Triage'}">
                            <v-icon
                            large
                            :color="triage_at ? 'primary' : 'grey'"
                            >
                            mdi-state-machine
                          </v-icon>
                            <span>Triage</span>
                            <div>{{ triage_at | formatRelativeDate }}</div>
                          </div>
                        </v-col>

                        <!-- Escalated State -->
                        <v-col cols="3" class="timeline-item">
                          <div class="timeline-content" :class="{'active': status === 'Escalated'}">
                            <v-icon
                            large
                            :color="escalated_at ? 'primary' : 'grey'"
                            >
                            mdi-state-machine
                          </v-icon>
                            <span>Escalated</span>
                            <div>{{ escalated_at | formatRelativeDate }}</div>
                          </div>
                        </v-col>

                        <!-- Closed State -->
                        <v-col cols="2" class="timeline-item">
                          <div class="timeline-content" :class="{'active': status === 'Closed'}">
                            <v-icon
                            large
                            :color="closed_at ? 'primary' : 'grey'"
                            >
                            mdi-state-machine
                          </v-icon>
                            <span>Closed</span>
                            <div>{{ closed_at | formatRelativeDate }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  </v-list-item-content>
                  <div class="ml-45">
                    <v-tooltip bottom v-if="status == 'New' || status == 'Triage'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small @click="showEscalateDialog(selected)" color="error" v-on="on" v-bind="attrs">
                          <v-icon>mdi-arrow-up</v-icon>
                          <v-icon>mdi-alert</v-icon>
                        </v-btn>
                      </template>
                      <span>Escalate Case (will create an incident)</span>
                    </v-tooltip>

                    <v-btn
                      @click="fetchDetails"
                      class="ml-7"
                      >
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    <v-spacer />

                    <v-tooltip bottom v-if="status == 'Escalated'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small @click="showEscalateDialog(selected)" color="error" v-on="on" v-bind="attrs">
                          <v-icon>mdi-plus</v-icon>
                          <v-icon>mdi-alert</v-icon>
                        </v-btn>
                      </template>
                      <span>Escalate Again (Will create one more incident)</span>
                    </v-tooltip>
                  </div>
                  <v-spacer />
                  <v-btn icon color="info" :loading="loading" :disabled="invalid || !validated" @click="save()">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-btn icon color="secondary" @click="closeEditSheet">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-list-item>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
      <v-tabs color="tips" fixed-tabs v-model="tab">
        <v-tab key="details">
          <v-icon class="mr-2">mdi-axis-arrow-info</v-icon>
          Details
        </v-tab>
        <v-tab key="resources">
          <v-icon class="mr-2">mdi-file-outline</v-icon>
          Resources
        </v-tab>
        <v-tab key="participants">
          <v-icon class="mr-2">mdi-account-group</v-icon>
          Participants
        </v-tab>
        <v-tab key="timeline">
          <v-icon class="mr-2">mdi-timeline-outline</v-icon>
          Timeline
        </v-tab>
        <v-tab key="entities">
          <v-icon class="mr-2">mdi-format-superscript</v-icon>
          Entities
        </v-tab>
        <v-tab key="workflows">
          <v-icon class="mr-2">mdi-cog-sync-outline</v-icon>
          Workflows
        </v-tab>
        <v-tab key="signals">
          <v-icon class="mr-2">mdi-signal-variant</v-icon>
          Signals
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="details">
          <case-details-tab />
        </v-tab-item>
        <v-tab-item key="resources">
          <case-resources-tab />
        </v-tab-item>
        <v-tab-item key="participants">
          <case-participants-tab />
        </v-tab-item>
        <v-tab-item key="timeline">
          <case-timeline-tab />
        </v-tab-item>
        <v-tab-item key="entities">
          <entities-tab
            :key="entityTabKey"
            :selected="selected"
            v-model="signal_instances"
            v-if="selected.signal_instances"
          />
        </v-tab-item>
        <v-tab-item key="workflow_instances">
          <workflow-instance-tab v-model="workflow_instances" />
        </v-tab-item>
        <v-tab-item key="signal_instances">
          <signal-instance-tab
            :key="entityTabKey"
            v-model="signal_instances"
            v-if="selected.signal_instances"
        />
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
  </ValidationObserver>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { ValidationObserver } from "vee-validate"
import moment from 'moment';

import CaseDetailsTab from "@/case/DetailsTab.vue"
import CaseParticipantsTab from "@/case/ParticipantsTab.vue"
import CaseParticipant from "@/case/Participant.vue"
import CaseResourcesTab from "@/case/ResourcesTab.vue"
import CaseTimelineTab from "@/case/TimelineTab.vue"
import WorkflowInstanceTab from "@/workflow/WorkflowInstanceTab.vue"
import SignalInstanceTab from "@/signal/SignalInstanceTab.vue"
import EntitiesTab from "@/entity/EntitiesTab.vue"
import Loading from "@/components/Loading.vue"

export default {
  name: "CaseEditSheet",

  components: {
    CaseDetailsTab,
    CaseResourcesTab,
    CaseParticipantsTab,
    CaseTimelineTab,
    WorkflowInstanceTab,
    SignalInstanceTab,
    EntitiesTab,
    ValidationObserver,
    CaseParticipant,
    Loading,
  },

  data() {
    return {
      tab: null,
      navigation: {
        width: 1300,
        borderSize: 3,
        minWidth: 400,
      },
      isLoading: true,
      entityTabKey: 0,
    }
  },

  computed: {
    ...mapFields("case_management", [
      "selected",
      "selected.assignee",
      "selected.id",
      "selected.name",
      "selected.project",
      "selected.reported_at",
      "selected.case_priority",
      "selected.triage_at",
      "selected.escalated_at",
      "selected.closed_at",
      "selected.status",
      "selected.loading",
      "selected.signal_instances",
      "selected.visibility",
      "selected.workflow_instances",
      "dialogs.showEditSheet",
    ]),
    computedWidth() {
    // Get the full screen width and subtract ~65px (width of mini nav)
      return this.$vuetify.breakpoint.width - 65;
    },
  },

  created() {
    this.fetchDetails()
  },

  watch: {
    "$route.params.name": function () {
      this.fetchDetails()
    },
  },

  filters: {
    formatRelativeDate(value) {
      if (value) {
        return moment(value).fromNow()
      }
      return value;
    },
  },

  methods: {
    statusIcon(status) {
      return this.status === status ? 'mdi-check' : '';
    },
    // My theory is the deep nested nature of signal_instance and complex data structures or dependencies.
    // was cuasing the issue. I have added a key to the entities-tab and signal-instance-tab components.
    // By forcing a re-render, you ensure that the component updates its content based on the latest data.
    // KJ.
    async fetchDetails() {
      await this.getDetails({ name: this.$route.params.name }).then(() => {
        this.entityTabKey++;
      })
    },
    ...mapActions("case_management", [
      "save",
      "getDetails",
      "closeEditSheet",
      "showEscalateDialog",
    ]),
  },
}
</script>

<style scoped>
.timeline-container {
  overflow-x: auto;
  white-space: nowrap;
}

.timeline-item {
  display: inline-block;
  text-align: center;
}

.timeline-content {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  font-size: 10px;
}

.timeline-content.active {
  transform: scale(1.1);
}

.timeline-item:not(:last-child) {
  position: relative; /* Required for absolute positioning of the pseudo-element */
}



</style>
