import { render, staticRenderFns } from "./IncidentEntityCard.vue?vue&type=template&id=fb6ca82c&scoped=true"
import script from "./IncidentEntityCard.vue?vue&type=script&lang=js"
export * from "./IncidentEntityCard.vue?vue&type=script&lang=js"
import style0 from "./IncidentEntityCard.vue?vue&type=style&index=0&id=fb6ca82c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6ca82c",
  null
  
)

export default component.exports