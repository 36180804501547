<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <v-row justify="center">
      <date-chip-group-relative
        class="pl-6 mt-6"
        label="Time Range"
        v-model="selectedDateTime"
        @input="onSelectedDateTimeChange"
      />
    </v-row>
    <v-row v-if="uniqueEntities.length >= 1">
      <v-col v-for="entity in uniqueEntities" :key="entity.id" cols="4" class="pl-6 pr-6">
        <incident-entity-card :entity="entity" :count="entity.count" :selectedDateTime="selectedDateTime" />
      </v-col>
    </v-row>
    <div v-else>
      <p class="text-center">No IAOs available. Click on the UnStruct logo at the bottom right to start crafting your knowledge universe.</p>
    </div>
  </v-container>
</template>

<script>
import EntityCard from "@/entity/EntityCard.vue"
import IncidentEntityCard from "@/entity/IncidentEntityCard.vue"
import DateChipGroupRelative from "@/components/DateChipGroupRelative.vue"

export default {
  name: "EntitiesTab",
  components: {
    EntityCard,
    DateChipGroupRelative,
    IncidentEntityCard,
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDateTime: 7,
    }
  },
  computed: {
    uniqueEntities() {
      const uniqueEntities = {}

      if (this.selected.signal_instances.length) {
        this.selected.signal_instances.forEach((instance) => {
          instance.entities.forEach((entity) => {
            const key = `${entity.entity_type}_${entity.value}`

            if (uniqueEntities[key]) {
              uniqueEntities[key].count++
            } else {
              uniqueEntities[key] = {
                ...entity,
                count: 1,
              }
            }
          })
        })
      }
      // order by occurrence date
      return Object.values(uniqueEntities).sort((a, b) => {
        const aDate = a.occurrences && a.occurrences.length > 0 ? new Date(a.occurrences[0].occurred_at) : new Date(0);
        const bDate = b.occurrences && b.occurrences.length > 0 ? new Date(b.occurrences[0].occurred_at) : new Date(0);
        return bDate - aDate;
      });
    },
  },
  methods: {
    onSelectedDateTimeChange(newValue) {
      this.selectedDateTime = newValue
    },
  },
}
</script>
