<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-card elevation="1" outlined class="position-relative">
    <v-row>
      <v-col cols="8" style="align-self: center">
        <v-card-title class="pb-0 mb-0 primary--text">
          {{ entity.value }}
        </v-card-title>
      </v-col>
      <v-col class="text-right" cols="4" v-if="count > 1" style="align-self: center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-badge color="blue-grey" :content="badgeCount" overlap offset-x="45" offset-y="37">
                <v-card-subtitle></v-card-subtitle>
              </v-badge>
            </span>
          </template>
          <span>This entity has been seen {{ count }} times in this case</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-card-text style="font-size: 18px">
      <v-chip>
        <v-icon left small>mdi-tag-multiple</v-icon>
        {{ entity.entity_type.name }}
      </v-chip>
      <!--Copy to clipboard-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="copyToClipboard(entity.value)"
            class="ml-2"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy Entity to Clipboard</span>
      </v-tooltip>
    </v-card-text>
    <v-divider></v-divider>

    <!--Signal card -->
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          outlined
          rounded="xl"
          class="d-flex align-center mx-4 mt-3 mb-3"
          :elevation="hover ? 2 : 0"
          @click="openSignalInstanceTab"
        >
          <v-card-text class="d-flex align-center">
            <v-progress-linear
              v-if="isLoading"
              height="3"
              color="pink"
              indeterminate
            ></v-progress-linear>
            <span v-else>
              <span v-if="signalInstanceCount > 1">
                  <v-icon left color="error">mdi-signal</v-icon>
                  Seen in
                  <span v-bind:class="{ 'font-weight-bold': signalInstanceCount > 5 }">{{ signalInstanceCount }}</span>
                  other signals
                </span>
                <span v-else>
                  <v-icon left>mdi-signal-off</v-icon>
                  First time this entity has been seen in a signal
                </span>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
     <!--Commit card -->
     <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          outlined
          rounded="xl"
          class="d-flex align-center mx-4 mt-3 mb-3"
          :elevation="hover ? 2 : 0"
          @click="openCommitTab"
        >
          <v-card-text class="d-flex align-center">
            <v-progress-linear
              v-if="isLoading"
              height="3"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <span v-else>
              <span v-if="commitCount > 0">
                <v-icon left color="error">mdi-source-branch-check</v-icon>
                Seen in
                <span v-bind:style="{ 'font-weight': commitCount > 0 ? 'bold' : 'normal', 'font-size': commitCount > 5 ? '20px' : '16px' }">{{ commitCount }}</span>
                other commits
              </span>
              <!--This entity has never been seen in a commit-->
              <span v-else>
                <v-icon>mdi-source-branch-remove</v-icon>
                This entity has never been seen in a commit
              </span>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
    <!--Case card -->
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          outlined
          rounded="xl"
          class="d-flex align-center mx-4 mt-3 mb-3"
          :elevation="hover ? 2 : 0"
          @click="openCaseTab"
        >
          <v-card-text class="d-flex align-center">
            <v-progress-linear
              v-if="isLoading"
              height="3"
              color="warning"
              indeterminate
            ></v-progress-linear>
            <span v-else>
              <span v-if="caseCount > 1">
                <v-icon left color="error">mdi-briefcase</v-icon>
                Seen in
                <span v-bind:class="{ 'font-weight-bold': caseCount > 5 }">{{ caseCount }}</span>
                other cases
              </span>
              <span v-else>
                <v-icon left>mdi-briefcase-off</v-icon>
                First time this entity has been seen in a case
              </span>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
    <v-dialog v-model="signalDialog" max-width="1080">
      <signal-instance-tab :inputSignalInstances="signalInstances" />
    </v-dialog>
    <v-dialog v-model="caseDialog" max-width="1080">
      <case-tab :inputCases="cases" />
    </v-dialog>
    <v-dialog v-model="commitDialog" max-width="1080">
      <commit-tab :inputCommits="commits" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"

import CaseApi from "@/case/api"
import CaseTab from "@/case/CaseTab.vue"
import EntityApi from "@/entity/api"
import SearchUtils from "@/search/utils"
import SignalInstanceApi from "@/signal/api"
import SignalInstanceTab from "@/signal/SignalInstanceTab.vue"
import CommitTab from "@/commit/CommitTab.vue"

export default {
  name: "EntityCard",
  props: {
    entity: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    },
    selectedDateTime: {
      type: Number,
      required: true,
    },
  },
  components: {
    "signal-instance-tab": SignalInstanceTab,
    "case-tab": CaseTab,
    "commit-tab": CommitTab,
  },
  computed: {
    ...mapFields("entity", ["dialogs.showCaseView"]),
    ...mapFields("route", ["query"]),
    badgeCount() {
      return this.count >= 100 ? "x99+" : `x${this.count}`
    },
  },
  data() {
    return {
      caseCount: null,
      signalInstanceCount: null,
      commitCount: null,
      isLoading: true,
      signalDialog: false,
      caseDialog: false,
      signalInstances: [],
      cases: [],
      commits: [],
      commitDialog: false,
    }
  },
  async mounted() {
    await this.refreshData();
    await this.refreshCommitData();
  },
  watch: {
    selectedDateTime() {
      this.refreshData()
    },
  },
  methods: {
    ...mapActions("query", ["copyToClipboardSuccess"]),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copyToClipboardSuccess()
      })
    },
    async refreshData() {
      try {
        this.isLoading = true

        const casePromise = EntityApi.getCases(this.entity.id, this.selectedDateTime).then(
          (response) => response.data
        )
        const signalPromise = EntityApi.getSignalInstances(
          this.entity.id,
          this.selectedDateTime
        ).then((response) => response.data)

        const [casesResponse, signalResponse] = await Promise.all([casePromise, signalPromise])

        this.cases = casesResponse.cases
        this.caseCount = this.cases.length

        this.signalInstances = signalResponse.instances
        this.signalInstanceCount = this.signalInstances.length

        this.isLoading = false
      } catch (error) {
        console.error("Error in refreshData:", error)
      }
    },
    async refreshCommitData() {
        try {
          this.isLoading = true

          const commitPromise = EntityApi.getCommits(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [commitsResponse] = await Promise.all([commitPromise])

          this.commits = commitsResponse.commits
          this.commitCount = this.commits.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshCommitData:", error)
        }
      },
      async openCommitTab() {
        this.commitDialog = true
        this.$nextTick(() => {
          this.$refs.commitTab = this.commits
        })
      },
    async openSignalInstanceTab() {
      this.signalDialog = true
      this.$nextTick(() => {
        this.$refs.signalInstanceTab = this.signalInstances
      })
    },
    async openCaseTab() {
      this.caseDialog = true
      this.$nextTick(() => {
        this.$refs.caseTab = this.cases
      })
    },
    getStartDate() {
      return new Date(Date.now() - this.selectedDateTime * 86400000).toISOString()
    },
  },
}
</script>
