<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="filteredTasks"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
      }"
      @click:row="handleRowClick"
      class="task-table"
    >
      <template v-slot:item.weblink="{ item }">
        <a :href="item.weblink" target="_blank" @click.stop>{{ item.weblink }}</a>
      </template>
      <template v-slot:item.resolve_by="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.resolve_by | formatRelativeDate }}</span>
          </template>
          <span>{{ item.resolve_by | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TaskSummaryTab",
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Description", value: "description", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Priority", value: "priority", sortable: true },
        { text: "Resolve By", value: "resolve_by", sortable: true },
        { text: "Weblink", value: "weblink", sortable: true },
        { text: "", value: "action", sortable: false, align: "end", width: "48px" },
      ],
    }
  },
  computed: {
    filteredTasks() {
      const searchLower = this.search.toLowerCase();
      return this.tasks.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: "Task",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
}
</script>

<style scoped>
.task-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.task-table ::v-deep tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.1) !important;
  box-shadow: 0 0 15px rgba(0, 188, 212, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.task-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.task-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
