<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-data-table
    :headers="headers"
    :items="individuals"
    :items-per-page="10"
    @click:row="handleRowClick"
    :footer-props="{
      'items-per-page-options': [25, 50, 100],
    }"
    class="individual-table"
  >
    <template v-slot:item.is_active="{ item }">
      <v-icon :color="item.is_active ? 'green' : 'red'">
        {{ item.is_active ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </template>
    <template v-slot:item.is_external="{ item }">
      <v-icon :color="item.is_external ? 'orange' : 'blue'">
        {{ item.is_external ? 'mdi-account-network' : 'mdi-account' }}
      </v-icon>
    </template>
    <template v-slot:item.action>
      <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "IndividualTab",
  props: {
    individuals: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Company", value: "company", sortable: true },
        { text: "Active", value: "is_active", sortable: true },
        { text: "External", value: "is_external", sortable: true },
        { text: "", value: "action", sortable: false, width: "48px" },
      ],
    };
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: "Individual",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.individual-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.individual-table ::v-deep tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.1) !important;
  box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.individual-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.individual-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
