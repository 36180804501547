<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      class="mb-4"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="filteredCommits"
      :items-per-page="10"
      @click:row="handleRowClick"
      class="elevation-1 commit-table"
    >
      <template v-slot:item.commit="{ item }">
        <commit-popover :commit="item" />
      </template>
      <template v-slot:item.commit_message="{ item }">
        <span>{{ item.commit_message }}</span>
      </template>
      <template v-slot:item.entities="{ item }">
        <template v-for="entity in item.entities">
          <span :key="entity.id">
            <entity-popover :entity="entity" />
          </span>
        </template>
      </template>
      <template v-slot:item.commit_author="{ item }">
        <span>{{ item.commit_author }}</span>
      </template>
      <template v-slot:item.commit_date="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.commit_date | formatRelativeDate }}</span>
          </template>
          <span>{{ item.commit_date }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click.stop="goToCommitUrl(item.commit_url)"
        >
          mdi-open-in-new
        </v-icon>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
  </template>

  <script>
  import CommitPopover from "@/commit/CommitPopover.vue"
  import EntityPopover from "@/entity/EntityPopover.vue"
  import { mapActions } from "vuex";

  export default {
    name: "CommitTable",
    components: {
      CommitPopover,
      EntityPopover,
    },
    props: {
      inputCommits: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        search: "",
        headers: [
          { text: "ID", value: "id", sortable: true },
          { text: "Commit", value: "commit" },
          { text: "Message", value: "commit_message" },
          { text: "Entities", value: "entities", sortable: false },
          { text: "Author", value: "commit_author" },
          { text: "Date", value: "commit_date" },
          { text: "Actions", value: "actions", sortable: false, align: "end" },
        ],
      };
    },
    methods: {
      goToCommitUrl(url) {
        window.open(url, "_blank");
      },
      ...mapActions('websocket', ['setActiveObject']),
      handleRowClick(item) {
        this.setActiveObject({
          type: "Commit",
          id: item.id,
        });
        this.$emit('close-dialog');
      },
    },
    computed: {
      filteredCommits() {
        const searchLower = this.search.toLowerCase();
        return this.inputCommits.filter(item =>
          Object.keys(item).some(key =>
            String(item[key]).toLowerCase().includes(searchLower)
          )
        );
      },
      commits() {
        if (this.inputCommits.length) {
          return this.inputCommits;
        }
        return [];
      },
    },
  };
  </script>

  <style scoped>
  .commit-table ::v-deep tbody tr {
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .commit-table ::v-deep tbody tr:hover {
    background-color: rgba(103, 58, 183, 0.1) !important;
    box-shadow: 0 0 15px rgba(103, 58, 183, 0.5);
  }

  .chat-hint {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .commit-table ::v-deep tbody tr:hover .chat-hint {
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .commit-table ::v-deep tbody tr:hover .chat-hint {
    animation: pulse 1.5s infinite;
  }
  </style>
