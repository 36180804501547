<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
    <v-row justify="end">
      <v-switch v-model="showDetails" label="Show details" />
      <v-btn
        color="secondary"
        class="ml-2 mr-2 mt-3"
        @click="exportToCSV()"
        :loading="exportLoading"
      ><v-icon left>mdi-export</v-icon>
        Export
      </v-btn>
    </v-row>
    <v-form ref="form">
      <v-row>
        <v-col cols="5">
          <v-textarea
            v-model="newComment"
            label="Add a comment"
            rows="1"
            auto-grow
            :rules="[rules.required]"
            prepend-icon="mdi-comment-text-outline"
            filled
          ></v-textarea>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-btn
            color="light-blue"
            @click="addComment"
            :disabled="isCommentEmpty"
            class="d-flex align-center"
          >
            <v-icon large left>mdi-send</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="5">
        <v-text-field
          v-model="search"
          label="Search..."
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-timeline v-if="events && events.length" dense clipped>
      <v-timeline-item
        v-for="event in sortedAndFilteredEvents"
        :key="event.id"
        class="mb-4"
        color="blue"
        small
      >
        <v-row justify="space-between">
          <v-col cols="7">
            {{ event.description }}
            <transition-group name="slide" v-if="showDetails">
              <template v-for="(value, key) in event.details">
                <v-card flat :key="key">
                  <v-card-title class="subtitle-1">
                    {{ key | snakeToCamel }}
                  </v-card-title>
                  <v-card-text>{{ value }}</v-card-text>
                </v-card>
              </template>
            </transition-group>
            <div class="caption">
              {{ event.source }}
            </div>
          </v-col>
          <v-col class="text-right" cols="5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="wavy-underline">{{
                  event.started_at | formatRelativeDate
                }}</span>
              </template>
              <span class="pre-formatted">{{ event.started_at | formatToTimeZones }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div v-else>
      <p class="text-center">No timeline data available.</p>
    </div>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import Util from "@/util"
import { mapState } from "vuex"
import { mapActions } from "vuex"
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "CaseTimelineTab",

  data() {
    return {
      showDetails: false,
      exportLoading: false,
      newComment: "",
      search: "",
      rules: {
        required: value => !!value || 'This field is required',
      },
    }
  },

  computed: {
    ...mapFields("case_management", ["selected.events", "selected.name", "selected.id"]),
    ...mapState("auth", ["currentUser"]),

    isCommentEmpty() {
      return this.newComment.trim() === "";
    },

    sortedAndFilteredEvents: function () {
      let events = this.events.slice().sort((a, b) => new Date(b.started_at) - new Date(a.started_at));

      if (this.search) {
        events = events.filter(event =>
          event.description.toLowerCase().includes(this.search.toLowerCase()) ||
          event.source.toLowerCase().includes(this.search.toLowerCase())

        );
      }
      return events;
    },
  },

  methods: {
    ...mapActions('case_management', ['addComment']),

    exportToCSV() {
      this.exportLoading = true
      let items = this.sortedEvents
      Util.exportCSV(items, this.name + "-timeline-export.csv")
      this.exportLoading = false
    },
    addComment() {
      if (this.$refs.form.validate()) {
        const commentEvent = {
          uuid: uuidv4(),
          description: this.newComment,
          details: {},
          source: this.currentUser.email,
          started_at: new Date().toISOString(),
          ended_at: new Date().toISOString(),
        };
        this.$set(this.events, this.events.length, commentEvent);
        this.$store.dispatch('case_management/addComment', { caseId: this.id, comment: commentEvent });
        this.newComment = "";
        this.$refs.form.resetValidation();
      }
    },

  },
}
</script>

<style scoped src="@/assets/styles/timeline.css" />
