<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
  <template>
  <v-card elevation="2" class="entity-card">
    <v-card-title class="d-flex justify-space-between align-center">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" @click="fetchCategory('eid:' + entity.id)" class="title-link">
            {{ truncateText(entity.value) }}
          </span>
        </template>
        <span v-if="lastOccurrence && lastOccurrence.description">
          {{ entity.value }}
        </span>
        <span v-else>
          Data is not available.
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="count > 1">
        <template v-slot:activator="{ on, attrs }">
          <v-badge color="primary" :content="badgeCount" overlap offset-x="10" offset-y="10">
            <v-icon small v-bind="attrs" v-on="on">mdi-note-search-outline</v-icon>
          </v-badge>
        </template>
        <span>This ADO has been logged {{ count }} times across all IAOs</span>
      </v-tooltip>
      <div>
        <v-btn icon small @click="subscribeToEntity">
          <v-icon color="error">mdi-bell-plus-outline</v-icon>
        </v-btn>
        <v-btn icon small @click="openOccurrencesTab">
          <v-icon color="primary">mdi-creation</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      <div class="metadata-section">
        <v-chip small class="mr-2 mb-2" :key="'entity-type'" @click="fetchCategory(entity.entity_type.name)">
          <v-icon left small>mdi-plus-circle-multiple-outline</v-icon>
          {{ entity.entity_type.name }}
        </v-chip>

        <v-chip small class="mr-2 mb-2" v-if="entity.entity_raw && entity.entity_raw.ontologyClass"
          :key="'ontology-class'" @click="fetchCategory(entity.entity_raw.ontologyClass)">
          <v-icon left small>mdi-vector-intersection</v-icon>
          {{ entity.entity_raw.ontologyClass }}
        </v-chip>

        <v-chip small class="mr-2 mb-2" v-if="entity.entity_raw && entity.entity_raw.ontologyParentClass"
          :key="'ontology-parent-class'" @click="fetchCategory(entity.entity_raw.ontologyParentClass)">
          <v-icon left small>mdi-vector-difference</v-icon>
          {{ entity.entity_raw.ontologyParentClass }}
        </v-chip>

        <v-chip small class="mr-2 mb-2" v-for="(category, index) in (entity.entity_raw && entity.entity_raw.categories) || []"
          :key="`category-${index}-${category}`" @click="fetchCategory(category)">
          <v-icon left x-small>mdi-vector-intersection</v-icon>
          {{ category }}
        </v-chip>

        <v-chip small class="mr-2 mb-2" v-for="(tag, index) in (entity.entity_raw && entity.entity_raw.tags) || []"
          :key="`tag-${index}-${tag}`" @click="fetchCategory(tag)">
          <v-icon left x-small>mdi-tag-outline</v-icon>
          {{ tag }}
        </v-chip>
      </div>

      <div class="date-info mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small v-bind="attrs" v-on="on" class="mr-1">mdi-sort-clock-descending-outline</v-icon>
          </template>
          <span>Updated at: {{ entity.updated_at | formatRelativeDate }}</span>
        </v-tooltip>
        <span class="mr-2">{{ entity.updated_at | formatRelativeDate }} |</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small v-bind="attrs" v-on="on" class="mr-1">mdi-update</v-icon>
          </template>
          <span>Last occurred at: {{ getLastOccurrenceDate }}</span>
        </v-tooltip>
        <span class="mr-2">{{ getLastOccurrenceDate }} |</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small v-bind="attrs" v-on="on" class="mr-1">mdi-calendar-outline</v-icon>
          </template>
          <span>Created at: {{ entity.created_at | formatRelativeDate }}</span>
        </v-tooltip>
        <span>{{ entity.created_at | formatRelativeDate }}</span>

      </div>
    </v-card-text>

    <v-card-text class="icons-section pa-2">
      <v-btn small @click="openIncidentTab" class="ma-1" :disabled="incidentCount === 0">
        <v-icon left>{{ incidentCount > 0 ? 'mdi-domain-plus' : 'mdi-domain-off' }}</v-icon>
        {{ incidentCount }} {{ incidentCount === 1 ? 'BAO' : 'IAOs' }}
      </v-btn>
      <v-btn small @click="openCommitTab" class="ma-1" :disabled="commitCount === 0">
        <v-icon left>{{ commitCount > 0 ? 'mdi-source-branch-plus' : 'mdi-api-off' }}</v-icon>
        {{ commitCount }} {{ commitCount === 1 ? 'commit' : 'commits' }}
      </v-btn>
      <v-btn small @click="openCaseTab" class="ma-1" :disabled="caseCount === 0">
        <v-icon left>{{ caseCount > 0 ? 'mdi-briefcase-plus-outline' : 'mdi-briefcase-off-outline' }}</v-icon>
        {{ caseCount }} {{ caseCount === 1 ? 'OCO' : 'OCOs' }}
      </v-btn>
      <v-btn small @click="openSignalInstanceTab" class="ma-1" :disabled="signalInstanceCount === 0">
        <v-icon left>{{ signalInstanceCount > 0 ? 'mdi-access-point-plus' : 'mdi-access-point-off' }}</v-icon>
        {{ signalInstanceCount }} {{ signalInstanceCount === 1 ? 'Signal Instance' : 'Signal Instances' }}
      </v-btn>
      <v-btn small @click="openTicketTab" class="ma-1" :disabled="ticketCount === 0">
        <v-icon left>{{ ticketCount > 0 ? 'mdi-ticket-confirmation' : 'mdi-card-bulleted-off-outline' }}</v-icon>
        {{ ticketCount }} {{ ticketCount === 1 ? 'ticket' : 'tickets' }}
      </v-btn>
      <v-btn small @click="openIndividualTab" class="ma-1" :disabled="individualCount === 0">
        <v-icon left>{{ individualCount > 0 ? 'mdi-account-plus' : 'mdi-account-off-outline' }}</v-icon>
        {{ individualCount }} individual data
      </v-btn>
      <v-btn small @click="openConversationTab" class="ma-1" :disabled="conversationCount === 0">
        <v-icon left>{{ conversationCount > 0 ? 'mdi-chat-plus-outline' : 'mdi-message-bulleted-off' }}</v-icon>
        {{ conversationCount }} {{ conversationCount === 1 ? 'conversation' : 'conversations' }}
      </v-btn>
      <v-btn small @click="openConferenceTab" class="ma-1" :disabled="conferenceCount === 0">
        <v-icon left>{{ conferenceCount > 0 ? 'mdi-video-plus-outline' : 'mdi-video-off-outline' }}</v-icon>
        {{ conferenceCount }} {{ conferenceCount === 1 ? 'conference' : 'conferences' }}
      </v-btn>
      <v-btn small @click="openDocumentTab" class="ma-1" :disabled="documentCount === 0">
        <v-icon left>{{ documentCount > 0 ? 'mdi-text-box-plus-outline' : 'mdi-cloud-off-outline' }}</v-icon>
        {{ documentCount }} {{ documentCount === 1 ? 'document' : 'documents' }}
      </v-btn>
      <v-btn small @click="openEmailTab" class="ma-1" :disabled="emailCount === 0">
        <v-icon left>{{ emailCount > 0 ? 'mdi-email-plus-outline' : 'mdi-email-off-outline' }}</v-icon>
        {{ emailCount }} {{ emailCount === 1 ? 'email' : 'emails' }}
      </v-btn>
      <v-btn small @click="openWeblinkTab" class="ma-1" :disabled="weblinkCount === 0">
        <v-icon left>{{ weblinkCount > 0 ? 'mdi-web' : 'mdi-earth-off' }}</v-icon>
        {{ weblinkCount }} {{ weblinkCount === 1 ? 'weblink' : 'weblinks' }}
      </v-btn>
      <v-btn small @click="openTaskTab" class="ma-1" :disabled="taskCount === 0">
        <v-icon left>{{ taskCount > 0 ? 'mdi-table-plus' : 'mdi-table-off' }}</v-icon>
        {{ taskCount }} {{ taskCount === 1 ? 'task' : 'tasks' }}
      </v-btn>
      <v-btn small @click="openFeedbackTab" class="ma-1" :disabled="feedbackCount === 0">
        <v-icon left>{{ feedbackCount > 0 ? 'mdi-comment-plus' : 'mdi-comment-off-outline' }}</v-icon>
        {{ feedbackCount }} {{ feedbackCount === 1 ? 'feedback' : 'feedback' }}
      </v-btn>
      <v-btn small @click="openHandoffTab" class="ma-1" :disabled="handoffCount === 0">
        <v-icon left>{{ handoffCount > 0 ? 'mdi-handshake-plus' : 'mdi-repeat-off' }}</v-icon>
        {{ handoffCount }} {{ handoffCount === 1 ? 'handoff' : 'handoffs' }}
      </v-btn>
      <v-btn small @click="openECCSProjectTab" class="ma-1" :disabled="eccsProjectCount === 0">
        <v-icon left>{{ eccsProjectCount > 0 ? 'mdi-shape-outline' : 'mdi-layers-off-outline' }}</v-icon>
        {{ eccsProjectCount }} {{ eccsProjectCount === 1 ? 'ECCS Project' : 'ECCS Projects' }}
      </v-btn>
      <v-btn small @click="openTextSubmissionTab" class="ma-1" :disabled="textSubmissionCount === 0">
        <v-icon left>{{ textSubmissionCount > 0 ? 'mdi-text-box-plus-outline' : 'mdi-clipboard-text-off-outline' }}</v-icon>
        {{ textSubmissionCount }} {{ textSubmissionCount === 1 ? 'text submission' : 'text submissions' }}
      </v-btn>
      <v-btn small @click="openFileSubmissionTab" class="ma-1" :disabled="fileSubmissionCount === 0">
        <v-icon left>{{ fileSubmissionCount > 0 ? 'mdi-paperclip' : 'mdi-battery-off-outline' }}</v-icon>
        {{ fileSubmissionCount }} {{ fileSubmissionCount === 1 ? 'file submission' : 'file submissions' }}
      </v-btn>
      <v-btn small @click="openYouTubeTranscriptSubmissionTab" class="ma-1" :disabled="youtubeTranscriptCount === 0">
        <v-icon left>{{ youtubeTranscriptCount > 0 ? 'mdi-youtube' : 'mdi-video-off-outline' }}</v-icon>
        {{ youtubeTranscriptCount }} {{ youtubeTranscriptCount === 1 ? 'YouTube Transcript' : 'YouTube Transcripts' }}
      </v-btn>
      <v-btn small @click="openOtherTab" class="ma-1" :disabled="otherCount === 0">
        <v-icon left>{{ otherCount > 0 ? 'mdi-plus' : 'mdi-minus-circle-off-outline' }}</v-icon>
        {{ otherCount }} {{ otherCount === 1 ? 'other' : 'others' }}
      </v-btn>
    </v-card-text>

    <v-divider></v-divider>

    <entity-occurrences
      :entityOccurrences="entity.occurrences"
      :entity="entity"
      :chartColor="chartColor"
    />

    <!-- Dialogs -->
    <v-dialog v-model="incidentDialog" max-width="1080">
    <incident-tab :inputIncidents="incidents" @close-dialog="incidentDialog = false" />
    </v-dialog>
    <v-dialog v-model="commitDialog" max-width="1080">
      <commit-tab :inputCommits="commits" @close-dialog="commitDialog = false" />
    </v-dialog>
    <v-dialog v-model="signalDialog" max-width="1080">
      <signal-instance-tab :inputSignalInstances="signalInstances" @close-dialog="signalDialog = false" />
    </v-dialog>
    <v-dialog v-model="caseDialog" max-width="1080">
      <case-tab :inputCases="cases" @close-dialog="caseDialog = false" />
    </v-dialog>
    <v-dialog v-model="ticketDialog" max-width="1080">
      <ticket-tab :inputTickets="tickets" @close-dialog="ticketDialog = false" />
    </v-dialog>
    <v-dialog v-model="occurenceDialog" max-width="1080">
      <entity-occurrence-table :entityOccurrences="entity.occurrences" @close-dialog="occurenceDialog = false" />
    </v-dialog>
    <v-dialog v-model="individualDialog" max-width="1080">
      <individual-tab :individuals="individuals" @close-dialog="individualDialog = false" />
    </v-dialog>
    <v-dialog v-model="conversationDialog" max-width="1080">
      <conversation-tab :conversations="conversations" @close-dialog="conversationDialog = false" />
    </v-dialog>
    <v-dialog v-model="conferenceDialog" max-width="1080">
      <conference-tab :conferences="conferences" @close-dialog="conferenceDialog = false" />
    </v-dialog>
    <v-dialog v-model="taskDialog" max-width="1080">
      <task-tab :tasks="tasks" @close-dialog="taskDialog = false" />
    </v-dialog>
    <v-dialog v-model="documentDialog" max-width="1080">
      <document-tab :documents="documents" @close-dialog="documentDialog = false" />
    </v-dialog>
    <v-dialog v-model="weblinkDialog" max-width="1080">
      <weblink-tab :weblinks="weblinks" @close-dialog="weblinkDialog = false" />
    </v-dialog>
    <v-dialog v-model="emailDialog" max-width="1080">
      <email-tab :emails="emails" @close-dialog="emailDialog = false" />
    </v-dialog>
    <v-dialog v-model="eccsProjectDialog" max-width="1080">
      <eccs-project-tab :eccsProjects="eccsProjects" @close-dialog="eccsProjectDialog = false" />
    </v-dialog>
    <v-dialog v-model="fileSubmissionDialog" max-width="1080">
      <file-submission-tab :fileSubmissions="fileSubmissions" @close-dialog="fileSubmissionDialog = false" />
    </v-dialog>
    <v-dialog v-model="textSubmissionDialog" max-width="1080">
      <text-submission-tab :textSubmissions="textSubmissions" @close-dialog="textSubmissionDialog = false" />
    </v-dialog>
    <v-dialog v-model="youtubeTranscriptDialog" max-width="1080">
      <youtube-transcript-tab :youtubeSubmissions="youtubeSubmissions" @close-dialog="youtubeTranscriptDialog = false" />
    </v-dialog>
  </v-card>
</template>

  <script>
  import { mapActions } from "vuex"
  import { mapFields } from "vuex-map-fields"

  import IncidentTab from "@/incident/IncidentTab.vue"
  import CommitTab from "@/commit/CommitTab.vue"
  import EntityApi from "@/entity/api"
  import CaseTab from "@/case/CaseTab.vue"
  import SignalInstanceTab from "@/signal/SignalInstanceTab.vue"
  import EntityOccurrences from "@/entity/EntityOccurrences.vue"
  import TicketTab from "@/ticket/TicketTab.vue"
  import EntityOccurrenceTable from "@/entity/EntityOccurrenceTable.vue"
  import IndividualTab from "@/individual/IndividualTab.vue"
  import ConversationTab from "@/conversation/ConversationTab.vue"
  import ConferenceTab from "@/conference/ConferenceTab.vue"
  import TaskSummaryTab from "@/task/TaskSummaryTab.vue"
  import DocumentTab from "@/document/DocumentTab.vue"
  import WeblinkTab from "@/webcrawl/WeblinkTab.vue"
  import ECCSProjectTab from "@/websocket/ECCSProjectTab.vue"
  import TextSubmissionTab from "@/user_submissions/TextSubmissionTab.vue"
  import FileSubmissionTab from "@/user_submissions/FileSubmissionTab.vue"
  import YouTubeTranscriptTab from "@/user_submissions/YouTubeTranscriptTab.vue"

  export default {
    name: "IncidentEntityCard",
    props: {
      entity: {
        type: Object,
        required: true,
      },
      count: {
        type: Number,
        default: 1,
      },
      selectedDateTime: {
        type: Number,
        required: true,
      },
      chartColor: {
        type: String,
        default: "primary",
      },
    },

    components: {
      "incident-tab": IncidentTab,
      "commit-tab": CommitTab,
      "case-tab": CaseTab,
      "signal-instance-tab": SignalInstanceTab,
      "entity-occurrences": EntityOccurrences,
      "ticket-tab": TicketTab,
      "entity-occurrence-table": EntityOccurrenceTable,
      "individual-tab": IndividualTab,
      "conversation-tab": ConversationTab,
      "conference-tab": ConferenceTab,
      "task-tab": TaskSummaryTab,
      "document-tab": DocumentTab,
      "weblink-tab": WeblinkTab,
      "eccs-project-tab": ECCSProjectTab,
      "text-submission-tab": TextSubmissionTab,
      "file-submission-tab": FileSubmissionTab,
      "youtube-transcript-tab": YouTubeTranscriptTab,
    },
    computed: {
      ...mapFields("entity", ["dialogs.showIncidentView"]),
      ...mapFields("route", ["query"]),
      getLastOccurrenceDate() {
        if (this.lastOccurrence && this.lastOccurrence.occurred_at) {
          return this.$options.filters.formatRelativeDate(this.lastOccurrence.occurred_at);
        }
        return 'UNKN';
      },
      badgeCount() {
        return this.count >= 100 ? "x99+" : `x${this.count}`
      },
      isDarkTheme() {
        return localStorage.getItem("dark_theme") === 'true';
      },
      iconColor() {
      return this.isDarkTheme ? 'white--text' : 'black--text';
      },
      bgColor() {
        return this.isDarkTheme ? 'purple darken-5' : 'purple lighten-5';
      },
      // Get the first occurrence of the entity, API returns the occurrences in descending order
      lastOccurrence() {
        return this.entity.occurrences[0]
      },
    },
    data() {
      return {
        incidentCount: null,
        caseCount: null,
        signalInstanceCount: null,
        commitCount: null,
        eccsProjectCount: null,
        textSubmissionCount: null,
        fileSubmissionCount: null,
        youtubeTranscriptCount: null,
        ticketCount: null,
        individualCount: null,
        conversationCount: null,
        conferenceCount: null,
        taskCount: null,
        documentCount: null,
        weblinkCount: null,
        emailCount: null,
        feedbackCount: null,
        handoffCount: null,
        otherCount: null,
        isLoading: true,
        incidentDialog: false,
        commitDialog: false,
        caseDialog: false,
        ticketDialog: false,
        signalDialog: false,
        individualDialog: false,
        conversationDialog: false,
        conferenceDialog: false,
        taskDialog: false,
        documentDialog: false,
        weblinkDialog: false,
        emailDialog: false,
        occurenceDialog: false,
        feedbackDialog: false,
        handoffDialog: false,
        eccsProjectDialog: false,
        textSubmissionDialog: false,
        fileSubmissionDialog: false,
        youtubeTranscriptDialog: false,
        otherDialog: false,
        incidents: [],
        commits: [],
        tickets: [],
        cases: [],
        signalInstances: [],
        individuals: [],
        conversations: [],
        conferences: [],
        tasks: [],
        documents: [],
        weblinks: [],
        emails: [],
        eccsProjects: [],
        textSubmissions: [],
        fileSubmissions: [],
        youtubeSubmissions: [],
        cardBGColor: null,
      }
    },
    async mounted() {
      await this.refreshIncidentData();
      await this.refreshCommitData();
      await this.refreshTicketData();
      await this.refreshData();
      await this.refreshIndividualData();
      await this.refreshConversationData();
      await this.refreshConferenceData();
      await this.refreshTaskData();
      await this.refreshDocumentData();
      await this.refreshWeblinkData();
      await this.refreshEmailData();
      await this.refreshFeedbackData();
      await this.refreshHandoffData();
      await this.refreshECCSProjectData();
      await this.refreshTextSubmissionData();
      await this.refreshFileSubmissionData();
      await this.refreshYouTubeTranscriptData();
      await this.refreshOtherData();
    },
    watch: {
    '$route' (to, from) {
       if (to.query.q !== from.query.q) {
         this.fetchCategory(to.query.q)
       }
      },
      selectedDateTime() {
        this.refreshIncidentData();
        this.refreshCommitData();
        this.refreshData();
        this.refreshTicketData();
        this.refreshIndividualData();
        this.refreshConversationData();
        this.refreshConferenceData();
        this.refreshTaskData();
        this.refreshDocumentData();
        this.refreshWeblinkData();
        this.refreshEmailData();
        this.refreshFeedbackData();
        this.refreshHandoffData();
        this.refreshECCSProjectData();
        this.refreshTextSubmissionData();
        this.refreshFileSubmissionData();
        this.refreshYouTubeTranscriptData();
        this.refreshOtherData();
      },
      isDarkTheme() {
        this.cardBGColor = this.bgColor;
      },
    },
    methods: {
      ...mapActions("query", ["copyToClipboardprimary"]),
      ...mapActions("entity", ["subscribe"]),

      async fetchCategory(category) {
        const newPath = this.$router.resolve({
          name: "entities",
          query: {
            q: category,
          },
        }).href;

        // Check if the only thing that changed is the query
        if (window.location.pathname + window.location.search === newPath) {
          // Force a full page reload
          window.location.href = newPath;
        } else {
          // Otherwise, use the router to navigate
          this.$router.push({ path: newPath });
        }
      },

      copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copyToClipboardprimary()
      })
      },
      // for feedback, handoff, and other, just simulate the data with a 1 second delay
      async refreshFeedbackData() {
        try {
          this.isLoading = true
          this.feedbackCount = null
          // Simulate the data with a 1 second delay
          setTimeout(() => {
            this.isLoading = false
            this.feedbackCount = 0
          }, 1000)
          this.feedbackCount = 0
          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshFeedbackData:", error)
        }
      },
      async refreshHandoffData() {
        try {
          this.isLoading = true
          this.handoffCount = null
          // Simulate the data with a 1 second delay
          setTimeout(() => {
            this.isLoading = false
            this.handoffCount = 0
          }, 1000)
          this.handoffCount = 0
          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshHandoffData:", error)
        }
      },
      async refreshOtherData() {
        try {
          this.isLoading = true
          this.otherCount = null
          // Simulate the data with a 1 second delay
          setTimeout(() => {
            this.isLoading = false
            this.otherCount = 0
          }, 1000)
          this.otherCount = 0
          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshOtherData:", error)
        }
      },
      async refreshData() {
        try {
          this.isLoading = true
          this.caseCount = null // so we get the UI effect.

          const casePromise = EntityApi.getCases(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )
          const signalPromise = EntityApi.getSignalInstances(
            this.entity.id,
            this.selectedDateTime
          ).then((response) => response.data)

          const [casesResponse, signalResponse] = await Promise.all([casePromise, signalPromise])

          this.cases = casesResponse.cases
          this.caseCount = this.cases.length

          this.signalInstances = signalResponse.instances
          this.signalInstanceCount = this.signalInstances.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshData:", error)
        }
      },
      async refreshECCSProjectData() {
        try {
          this.isLoading = true
          this.eccsProjectCount = null

          const eccsProjectPromise = EntityApi.getECCSProjects(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [eccsProjectsResponse] = await Promise.all([eccsProjectPromise])

          this.eccsProjects = eccsProjectsResponse.eccsProjects
          this.eccsProjectCount = this.eccsProjects.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshECCSProjectData:", error)
        }
      },
      async refreshTextSubmissionData() {
        try {
          this.isLoading = true;
          this.textSubmissionCount = null;
          const textSubmissionPromise = EntityApi.getTextSubmissions(this.entity.id, this.selectedDateTime);

          const textSubmissions = await textSubmissionPromise;

          this.textSubmissions = textSubmissions.data; // fastapi, resp model ;).
          this.textSubmissionCount = this.textSubmissions.length;
          this.isLoading = false;
        } catch (error) {
          console.error("Error in refreshTextSubmissionData:", error);
          this.isLoading = false;
        }
      },
      async refreshYouTubeTranscriptData() {
        try {
          this.isLoading = true;
          this.youtubeTranscriptCount = null;
          const youtubeTranscriptPromise = EntityApi.getYouTubeTranscripts(this.entity.id, this.selectedDateTime);

          const youtubeTranscripts = await youtubeTranscriptPromise;

          this.youtubeSubmissions = youtubeTranscripts.data;
          this.youtubeTranscriptCount = this.youtubeSubmissions.length;
          this.isLoading = false;
        } catch (error) {
          console.error("Error in refreshYouTubeTranscriptData:", error);
          this.isLoading = false;
        }
      },
      async refreshFileSubmissionData() {
        try {
          this.isLoading = true;
          this.fileSubmissionCount = null;
          const fileSubmissionPromise = EntityApi.getFileSubmissions(this.entity.id, this.selectedDateTime);
          const fileSubmissions = await fileSubmissionPromise;

          this.fileSubmissions = fileSubmissions.data;
          this.fileSubmissionCount = this.fileSubmissions.length;
          this.isLoading = false;
        } catch (error) {
          console.error("Error in refreshFileSubmissionData:", error);
          this.isLoading = false;
        }
      },
      async refreshIncidentData() {
        try {
          this.isLoading = true
          this.incidentCount = null

          const incidentPromise = EntityApi.getIncidents(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [incidentsResponse] = await Promise.all([incidentPromise])

          this.incidents = incidentsResponse.incidents
          this.incidentCount = this.incidents.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshIncidentData:", error)
        }
      },
      async refreshCommitData() {
        try {
          this.isLoading = true
          this.commitCount = null

          const commitPromise = EntityApi.getCommits(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [commitsResponse] = await Promise.all([commitPromise])

          this.commits = commitsResponse.commits
          this.commitCount = this.commits.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshCommitData:", error)
        }
      },
      async refreshTicketData() {
        try {
          this.isLoading = true
          this.ticketCount = null

          const ticketPromise = EntityApi.getTickets(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [ticketsResponse] = await Promise.all([ticketPromise])

          this.tickets = ticketsResponse.tickets
          this.ticketCount = this.tickets.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshTicketData:", error)
        }
      },
      async refreshIndividualData() {
        try {
          this.isLoading = true
          this.individualCount = null

          const individualPromise = EntityApi.getIndividuals(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [individualsResponse] = await Promise.all([individualPromise])

          this.individuals = individualsResponse.individuals
          this.individualCount = this.individuals.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshIndividualData:", error)
        }
      },
      async refreshConversationData() {
        try {
          this.isLoading = true
          this.conversationCount = null

          const conversationPromise = EntityApi.getConversations(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [conversationsResponse] = await Promise.all([conversationPromise])

          this.conversations = conversationsResponse.conversations
          this.conversationCount = this.conversations.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshConversationData:", error)
        }
      },
      async refreshConferenceData() {
        try {
          this.isLoading = true
          this.conferenceCount = null

          const conferencePromise = EntityApi.getConferences(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [conferencesResponse] = await Promise.all([conferencePromise])

          this.conferences = conferencesResponse.conferences
          this.conferenceCount = this.conferences.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshConferenceData:", error)
        }
      },
      async refreshTaskData() {
        try {
          this.isLoading = true
          this.taskCount = null

          const taskPromise = EntityApi.getTasks(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [tasksResponse] = await Promise.all([taskPromise])

          this.tasks = tasksResponse.tasks
          this.taskCount = this.tasks.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshTaskData:", error)
        }
      },
      async refreshDocumentData() {
        try {
          this.isLoading = true
          this.documentCount = null

          const documentPromise = EntityApi.getDocuments(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [documentsResponse] = await Promise.all([documentPromise])

          this.documents = documentsResponse.documents
          this.documentCount = this.documents.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshDocumentData:", error)
        }
      },
      async refreshWeblinkData() {
        try {
          this.isLoading = true
          this.weblinkCount = null

          const weblinkPromise = EntityApi.getWeblinks(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [weblinksResponse] = await Promise.all([weblinkPromise])

          this.weblinks = weblinksResponse.weblinks
          this.weblinkCount = this.weblinks.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshWeblinkData:", error)
        }
      },
      async refreshEmailData() {
        try {
          this.isLoading = true
          this.emailCount = null

          const emailPromise = EntityApi.getEmails(this.entity.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [emailsResponse] = await Promise.all([emailPromise])

          this.emails = emailsResponse.emails
          this.emailCount = this.emails.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshEmailData:", error)
        }
      },
      async openCommitTab() {
        this.commitDialog = true
        this.$nextTick(() => {
          this.$refs.commitTab = this.commits
        })
      },
      async openIncidentTab() {
        this.incidentDialog = true
        this.$nextTick(() => {
          this.$refs.incidentTab = this.incidents
        })
      },
      async openSignalInstanceTab() {
        this.signalDialog = true
        this.$nextTick(() => {
          this.$refs.signalInstanceTab = this.signalInstances
        })
      },
      async openCaseTab() {
        this.caseDialog = true
        this.$nextTick(() => {
          this.$refs.caseTab = this.cases
        })
      },
      async openTicketTab() {
        this.ticketDialog = true
        this.$nextTick(() => {
          this.$refs.ticketTab = this.tickets
        })
      },
      async openIndividualTab() {
        this.individualDialog = true
        this.$nextTick(() => {
          this.$refs.individualTab = this.individuals
        })
      },
      async openConversationTab() {
        this.conversationDialog = true
        this.$nextTick(() => {
          this.$refs.conversationTab = this.conversations
        })
      },
      async openConferenceTab() {
        this.conferenceDialog = true
        this.$nextTick(() => {
          this.$refs.conferenceTab = this.conferences
        })
      },
      async openTaskTab() {
        this.taskDialog = true
        this.$nextTick(() => {
          this.$refs.taskTab = this.tasks
        })
      },
      async openDocumentTab() {
        this.documentDialog = true
        this.$nextTick(() => {
          this.$refs.documentTab = this.documents
        })
      },
      async openWeblinkTab() {
        this.weblinkDialog = true
        this.$nextTick(() => {
          this.$refs.weblinkTab = this.weblinks
        })
      },
      async openEmailTab() {
        this.emailDialog = true
        this.$nextTick(() => {
          this.$refs.emailTab = this.emails
        })
      },
      async openECCSProjectTab() {
        this.eccsProjectDialog = true
        this.$nextTick(() => {
          this.$refs.eccsProjectTab = this.eccsProjects
        })
      },
      async openTextSubmissionTab() {
        this.textSubmissionDialog = true
        this.$nextTick(() => {
          this.$refs.textSubmissionTab = this.textSubmissions
        })
      },
      async openFileSubmissionTab() {
        this.fileSubmissionDialog = true
        this.$nextTick(() => {
          this.$refs.fileSubmissionTab = this.fileSubmissions
        })
      },
      async openYouTubeTranscriptSubmissionTab() {
        this.youtubeTranscriptDialog = true
        this.$nextTick(() => {
          this.$refs.youtubeTranscriptTab = this.youtubeTranscripts
        })
      },
      async openFeedbackTab() {
        this.feedbackDialog = true
        this.$nextTick(() => {
          this.$refs.feedbackTab = this.feedbacks
        })
      },
      async openHandoffTab() {
        this.handoffDialog = true
        this.$nextTick(() => {
          this.$refs.handoffTab = this.handoffs
        })
      },
      async openOtherTab() {
        this.otherDialog = true
        this.$nextTick(() => {
          this.$refs.otherTab = this.others
        })
      },
      async subscribeToEntity() {
        try {
          await this.subscribe(this.entity.id)
        } catch (error) {
          console.error("Error in subscribeToEntity:", error)
        }
      },
      openOccurrencesTab() {
          this.occurenceDialog = true
          this.$nextTick(() => {
            this.$refs.occurrencesTab = this.entity.occurrences
          })
      },
      getStartDate() {
        return new Date(Date.now() - this.selectedDateTime * 86400000).toISOString()
      },
      truncateText(text) {
        if (!text) {
          return ''
        }
        return text.length > 100 ? text.substring(0, 100) + "..." : text
      },
    },
  }
  </script>


<style scoped>
.entity-card {
  backdrop-filter: blur(10px);
  border-radius: 15px;  /*  */
  transition: all 0.3s ease;
}

.theme--dark .entity-card {
  background: linear-gradient(90deg, rgba(230, 240, 255, .1) 0, rgba(230, 240, 255, .2) 50%, rgba(230, 240, 255, .1));
}

.entity-card:hover {
  box-shadow: 0 12px 48px rgba(0, 198, 255, 0.2);
  transform: translateY(-5px);
}

.entity-value-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.subtitle-section {
  font-size: 14px;
  line-height: 1.6;
}

.subtitle-info {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.subtitle-label {
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.7);
}

.subtitle-value {
  font-weight: 500;
}

.v-tooltip__content {
  max-width: 300px;
  font-size: 14px;
  border-radius: 8px;
  background: rgba(45, 45, 45, 0.98) !important; /* Solid background with slight opacity */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.theme--light .v-tooltip__content {
  background: rgba(255, 255, 255, 0.98) !important;
}

.padded-card {
  padding: 20px;
}

.title-link {
  cursor: pointer;
  transition: color 0.3s ease;
}

.title-link:hover {
  color: #004bfb;
}

.metadata-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.v-chip {
  margin: 4px;
  transition: all 0.3s ease;
}

.v-chip:hover {
  background: rgba(0, 198, 255, 0.2) !important;
  box-shadow: 0 0 12px rgba(0, 198, 255, 0.3);
}

.icons-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
}

.v-btn {
  margin: 4px;
  transition: all 0.3s ease;
}

.v-btn:hover {
  background: rgba(0, 198, 255, 0.1) !important;
  box-shadow: 0 0 12px rgba(0, 198, 255, 0.3);
}

.date-info {
  font-size: 12px;
  margin-top: 10px;
}

</style>
