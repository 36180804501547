<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip pill small v-on="on">
            <v-avatar color="secondary" left>
              <v-icon class="white--text">mdi-source-commit</v-icon>
            </v-avatar>
            {{ truncateText(commit.commit_message) }}
          </v-chip>
        </template>
        <v-card width="400">
          <v-list dark>
            <v-list-item>
              <v-list-item-avatar color="secondary">
                <v-icon class="white--text">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ commit.commit_author }}</v-list-item-title>
                <v-list-item-subtitle>{{ commit.commit_date | formatRelativeDate }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon :href="commit.commit_url" target="_blank">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-code-tags</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ commit.repository_name }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-web</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>
                <a :href="commit.repository_url" target="_blank">{{ commit.repository_url }}</a>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-source-branch</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ commit.repository_branch }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ commit.source }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-creation</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>
                <commit-ai-summary-dialog :summary="commit.commit_summary" />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-source-commit</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>
                <commit-raw-dialog :commit_raw="commit.commit_raw" />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </template>

<script>
import CommitAiSummaryDialog from '@/commit/CommitAiSummaryDialog.vue';
import CommitRawDialog from '@/commit/CommitRawDialog.vue';
  export default {
  components: { CommitAiSummaryDialog, CommitRawDialog},
    name: "CommitPopover",
    props: {
      commit: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
      menu: false,
    }),
    methods: {
      truncateText(text) {
        if (text.length > 50) {
          return text.substring(0, 50) + "...";
        }
        return text;
      },
    }
  };
  </script>
