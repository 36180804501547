<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="filteredTickets"
      :items-per-page="10"
      class="elevation-1 ticket-table"
      @click:row="handleRowClick"
    >
      <template v-slot:item.entities="{ item }">
        <template v-for="entity in item.entities">
          <span :key="entity.id">
            <entity-popover
              :entity="entity"
            />
          </span>
        </template>
      </template>
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.created_at }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click.stop="goToTicketUrl(item.weblink)"
        >
          mdi-open-in-new
        </v-icon>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TicketPopover from "@/ticket/TicketPopover.vue"
import EntityPopover from "@/entity/EntityPopover.vue"
import { mapActions } from "vuex"

export default {
  name: "TicketTable",
  components: {
    TicketPopover,
    EntityPopover,
  },
  props: {
    inputTickets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Resource ID", value: "resource_id_ticket" },
        { text: "Weblink", value: "weblink" },
        { text: "Created At", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "end", width: "100px" },
      ],
    };
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    goToTicketUrl(url) {
      window.open(url, "_blank");
    },
    handleRowClick(item) {
      this.setActiveObject({
        type: "Ticket",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
  computed: {
    tickets() {
      if (this.inputTickets.length) {
        return this.inputTickets;
      }
      return [];
    },
    filteredTickets() {
      const searchLower = this.search.toLowerCase();
      return this.tickets.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
  },
};
</script>

<style scoped>
.ticket-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.ticket-table ::v-deep tbody tr:hover {
  background-color: rgba(255, 152, 0, 0.1) !important;
  box-shadow: 0 0 15px rgba(255, 152, 0, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ticket-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.ticket-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
