<template>
  <div>
    <!-- Search Input -->
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="filteredDocuments"
      :items-per-page="10"
      @click:row="handleRowClick"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
      }"
      class="document-table"
    >
      <template v-slot:item.created_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.created_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.weblink="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a :href="item.weblink" target="_blank" v-bind="attrs" v-on="on" class="truncate-text">{{ item.weblink }}</a>
          </template>
          <span>{{ item.weblink }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'DocumentTab',
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", sortable: true, width: "10%" },
        { text: "Resource ID", value: "resource_id", sortable: true, width: "15%" },
        { text: "Resource Type", value: "resource_type", sortable: true, width: "15%" },
        { text: "Created At", value: "created_at", sortable: true, width: "15%" },
        { text: "Weblink", value: "weblink", sortable: true },
        { text: "", value: "action", sortable: false, width: "48px" },
      ],
    };
  },
  computed: {
    filteredDocuments() {
      const searchLower = this.search.toLowerCase();
      return this.documents.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: "Document",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.document-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.document-table ::v-deep tbody tr:hover {
  background-color: rgba(156, 39, 176, 0.1) !important;
  box-shadow: 0 0 15px rgba(156, 39, 176, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.document-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.document-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}

.truncate-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>
